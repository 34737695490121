import React, { Component } from 'react';
import HelmetEx from '../../components/helmet-ex';
import Layout from '../../components/layout';
import ServicePagePortal from '../../components/service-page-portal';

class DesignAndDecoratingServices extends Component {
    render() {
        return (
            <Layout>
                <div className="design-and-decorating-services-page">
                    <HelmetEx>
                        <div metadata="title">Drapery & Valance Design | Furniture Upholstery | Elmwood Park NJ</div>
                        <div metadata="keywords">
                            Interior,services,design,consultation,drapery,furniture,upholstery,bergen county,NJ
                        </div>
                        <div metadata="description">
                            Hire us a call to arrange an after-hours or in-home visit,
                            Look to us for a unique solution that expresses your taste,
                            reflects your lifestyle and addresses any challenges
                        </div>
                    </HelmetEx>
                    <section className="header">
                        <div className="container">
                            <div className="content">
                                <div className="text">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h1>DESIGN AND DECORATING</h1>
                                            <h3>Give us a call to arrange an after-hours or in-home vist.</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="below-navigation-section">
                        <div className="container">
                            <ServicePagePortal activePath={"/services/design-and-decorating-services"}>
                                <section>
                                    <h4>AFTER-HOURS AND IN-HOME DESIGN CONSULTATIONS</h4>
                                    <p className="first-child-p pt-4 pt-lg-0">
                                        Give us a call to arrange an after-hours or in-home visit.
                                    </p>
                                </section>
                                <section>
                                    <h4>DRAPERY AND VALANCE DESIGN</h4>
                                    <p className="first-child-p pt-4 pt-lg-0">
                                        Look to us for a unique solution that expresses your taste,
                                        reflects your lifestyle and addresses any challanges
                                    </p>
                                </section>
                                <section>
                                    <h4>FURNITURE UPHOLSTERY</h4>
                                    <p className="first-child-p pt-4 pt-lg-0">
                                        Count on our upholstery department to re-craft your favorite piece of furniture or create a new
                                        piece totally from scratch.
                                    </p>
                                </section>
                            </ServicePagePortal>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default DesignAndDecoratingServices;